<template>
  <div v-bind:style="styles">
    <div  v-if="!viLoader">
      <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top:0">Certificate Request</h1>
        <button
          type="button"
          style="width:auto;height:36px;"
          class="btn btnsml"
          id="svbtn"
          @click="generateSectionOpen"
        >
          Generate Certificate
        </button>
        <button type="button" class="backbtn" @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div> -->
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 8px"
                  ><i class="fas fa-users"></i
                ></span>
                <h1>Request Details</h1>
              </div>
             
        <div style="width: 220px" class="flexitm">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="searchCertificateType"
                  > 
                    <option value=''>--Select--</option>
                    <option
                      v-for="(item, index) in filterCertificateTypes"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="acyearid"
                    >Sort By Certificate Type <span class="required">*</span></label
                  >
                </span>
                
              </div>
              <div style="width: 200px" class="flexitm">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="searchWords"
                  >
                    <option value=''>--Select--</option>
                    <option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="acyearid"
                    >Sort By Status <span class="required">*</span></label
                  >
                </span>
                
              </div>
              <div class="dispflex" >
                <button
          type="button"
          style="width:auto;height:36px;"
          class="btn btnsml"
          id="svbtn"
          @click="generateSectionOpen"
        >
          Generate Certificate
        </button>
            </div>
           
          </div>
          <div class="widjetcontent">
              <div class="restable">
                <div class="resrow resheader">
                  <!-- <div class="cell">S.No.</div> -->
                  <div class="cell">Certificate Request ID</div>
                  <div class="cell" style="text-align: center;">Certificate Type</div>
                  <div class="cell" style="text-align: center;">Student Name</div>
                  <div class="cell">Parent Name</div>
                  <div class="cell" style="text-align: center;">Status</div>
                  <div class="cell">Created By</div>
                  <div class="cell">Approved / Rejected By</div>

                  <div class="cell" style="text-align: center;">Action</div>
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in onlineTcList"
                  :key="index"
                >
                  <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                  <div class="cell" data-title="Candidate Name" style="text-align: center;">
                    {{ data.tcID }}
                  </div>
                  <div class="cell" data-title="Candidate Name" style="text-align: center;">
                    {{ data.certificateType || "" }}
                  </div>
                  <div class="cell" data-title="Parent Name" style="text-align: center;">
                    <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${data.student._id}`)">{{ data.student.firstName }} </a>
                    <!-- {{ data.student ? data.student.firstName : "" }} -->
                  </div>
                  <div class="cell" data-title="Parent Name" style="text-align: center;">
                    {{ data.parent ? data.parent.firstName : "" }}
                  </div>
                  <div class="cell" data-title="Email" style="text-align: center;">
                    {{ data.status }}
                  </div>
                  <div class="cell" data-title="Candidate Name" style="text-align: center;">
                    {{ data.requestCreatedBy || "" }}
                  </div>
                  <div class="cell" data-title="Enrollment Status" style="text-align: center;">
                    {{ data.actionBy ? data.actionBy : "-" }}
                  </div>

                  <div class="cell" data-title="Action">
                    <button
                      :disabled="data.admissionStatus == 'Active'"
                      class="btn round orange tooltipt"
                      @click.prevent="openEditPopUp(index)"
                    >
                      <i class="far fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                     <button
                     :disabled="data.status == 'Approved' || data.status == 'Generated' || data.status == 'Online' || data.status == 'Offline' || data.status == 'Couriered' || data.status == 'In-Person Collect'"
                      class="btn round red tooltipt"
                      @click="showDeleteModal(data._id)"
                    >
                      <i class="far fa-trash-alt"></i>
                      <span class="tooltiptext">Delete</span>
                    </button>
                    <b-dropdown
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                      <b-dropdown-item
                      v-if="
                        data.status == 'Submitted' &&
                        data.requestCreatedBy != 'School Admin' &&
                        data.certificateType == 'Transfer Certificate'
                      "
                      @click.prevent="openGenerateNeedMoreInfoPopUp(data._id, data.comments)"
                        >Need More Info</b-dropdown-item
                      >
                     

                      <b-dropdown-item
                      v-if="data.status == 'Submitted' || data.status == 'Verified'"
                      @click.prevent="updateAction(data._id, 'Approved', data.status)"
                        >Approve</b-dropdown-item
                      >
                      <b-dropdown-item
                      @click.prevent="updateAction(data._id, 'Rejected', data.status)"
                      v-if="data.status == 'Submitted' || data.status == 'Verified'"
                        >Reject</b-dropdown-item
                      >
                      <b-dropdown-item
                      v-if="
                        data.status == 'Approved' ||
                        data.status == 'Generated' ||
                        data.status == 'Online' ||
                        data.status == 'Offline' ||
                        data.status == 'Couriered' ||
                        data.status == 'In-Person Collect'
                      "
                      @click="print(index)"
                        >Print</b-dropdown-item
                      >
                      <b-dropdown-item
                      v-if="data.status == 'Approved'"
                      @click.prevent="openGeneratePopUp(data._id)"
                        >Send</b-dropdown-item
                      >
                    </b-dropdown>

                    <!-- <img
                      v-if="
                        data.status == 'Submitted' || data.status == 'Verified'
                      "
                      @click.prevent="
                        updateAction(data._id, 'Approved', data.status)
                      "
                      width="23px"
                      src="../../assets/img/approve_icon.svg"
                      alt="Approve"
                      title="Approve"
                    />
                    <img
                      @click.prevent="
                        updateAction(data._id, 'Rejected', data.status)
                      "
                      v-if="
                        data.status == 'Submitted' || data.status == 'Verified'
                      "
                      width="26px"
                      src="../../assets/img/reject_icon.svg"
                      alt="Reject"
                      title="Reject"
                    /> -->

                    <!-- <button
                      v-if="
                        data.status == 'Approved' ||
                        data.status == 'Generated' ||
                        data.status == 'Online' ||
                        data.status == 'Offline' ||
                        data.status == 'Couriered' ||
                        data.status == 'In-Person Collect'
                      "
                      @click="print(index)"
                      class="btn round blue tooltipt"
                    >
                      <i class="fas fa-print"></i
                      ><span class="tooltiptext">Print</span>
                    </button>
                    <button class="btn round blue tooltipt" style="background: #0c6db1;"
                      v-if="data.status == 'Approved'"
                      @click.prevent="openGeneratePopUp(data._id)"
                    >
                    <i class="fa fa-paper-plane" aria-hidden="true" style="padding:0"></i><span class="tooltiptext">Send</span>
                    </button> -->
                    <!-- <button type="button" v-if="!editId" class="btn btnsml" id="svbtn" @click.prevent="createOnlineTc">  <span>Submit</span></button> -->

                    <!-- <button  style="border-radius: 36px;margin-left: 3px;"
                      v-if="
                        data.status == 'Submitted' &&
                        data.requestCreatedBy != 'School Admin' &&
                        data.certificateType == 'Transfer Certificate'
                      "
                      @click.prevent="
                        openGenerateNeedMoreInfoPopUp(data._id, data.comments)
                      "
                    >
                      Need More Info
                    </button> -->
                  </div>
                </div>
              </div>
            </div>
                        <div class="widjetfooter text-right dk_iconsml" >

            <div class="text-center mt-1" v-if="onlineTcList.length > 0">
              <div class="pagination" id="pagination" style="padding-bottom:0">
                <a
                  :style="
                    currentPageNumber === 1 ? 'pointer-events: none;' : ''
                  "
                  @click="goToPreviousPage"
                  >&laquo;</a
                >
                <!-- class="active"  -->
                <a
                  v-for="(item, index) in lastPageNumber"
                  :key="index"
                  v-on:click="selected = item"
                  v-bind:class="{ active: selected == item }"
                  @click="navToSelectedPage(item)"
                  >{{ item }}</a
                >
                <a
                  :style="
                    currentPageNumber === lastPageNumber
                      ? 'pointer-events: none;'
                      : ''
                  "
                  @click="goToNextPage"
                  >&raquo;</a
                >
              </div>
            </div>
        </div>
      </div>
    </div>
    
  </div>
   <div>
  <div id="printThis" >
    <b-modal id="printSection" class="modal">
      <button type="button" class="close"  @click.prevent="closeEditPopUp">×</button>

      <div class="contact-form">
        <h2>Student Certificate Information</h2>
        <br />
        <div style="height: 350px; overflow-y: auto">
          <form
            data-vv-scope="academicyr"
            id="academicyr"
            accept-charset="utf-8"
          >  <div
              class="form-row p-2"
              
            >
              <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    v-model="editOnlineTcRequest.studentName"
                    class="form-control form-input"
                    id="curnbranid"
                  />

                  <label for="tocmts"
                    >Student Name <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                 <input
                    type="text"
                    disabled
                    name="currentbranch"
                    v-model="editOnlineTcRequest.certificateType"
                    class="form-control form-input"
                    id="curnbranid"
                  />
                  <label for="tocmts"
                    >Certificate Type <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div class="form-row p-2" v-if="editOnlineTcRequest.certificateType == 'Transfer Certificate'">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    class="form-control form-input"
                    id="tctypeid"
                    name="tctype"
                    v-model="editOnlineTcRequest.type"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in typeList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.tctype')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Type is required</label
                  >

                  <label v-else for="tctypeid"
                    >Type <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="currentbranch"
                    v-model="editOnlineTcRequest.schoolName"
                    class="form-control form-input"
                    id="curnbranid"
                  />
                  <label for="curnbranid"
                    >Current School/Branch
                    <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="statuslistid"
                    name="statuslist"
                    v-model="editOnlineTcRequest.status"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="statuslistid"
                    >Status <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 mt-2">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="reasonid"
                    name="reason"
                    v-validate="'required'"
                    v-model="editOnlineTcRequest.reason"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in reasonList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.reason')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Reason for Transfer is required</label
                  >
                  <label v-else for="reasonid"
                    >Reason for Transfer <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                  <textarea
                    v-validate="'required'"
                    name="comment"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                    v-model="editOnlineTcRequest.comments"
                  ></textarea>
                  <label
                    v-if="errors.first('academicyr.comment')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Comments is required</label
                  >
                  <label v-else for="tocmts"
                    >Comments <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    class="form-control form-input"
                    onkeydown="return false"
                    v-model="editOnlineTcRequest.lastAttend"
                    name="schoolLastDay"
                    v-validate="'required'"
                    id="addlid"
                    placeholder="Admission Deadline"
                  />
                  <label
                    v-if="errors.first('academicyr.schoolLastDay')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Last day at School is required</label
                  >
                  <label v-else for="reasonid"
                    >Last day at School <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    class="form-control form-input"
                    onkeydown="return false"
                    v-model="editOnlineTcRequest.dateOfLeaving"
                    name="dateOfLeaving"
                    v-validate="'required'"
                    id="addlid"
                    placeholder="Admission Deadline"
                  />

                  <label
                    v-if="errors.first('academicyr.dateOfLeaving')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Date of Leaving the School is required</label
                  >
                  <label v-else for="reasonid"
                    >Date of Leaving the School
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <textarea
                    name="charAndConduct"
                    class="form-control form-input"
                    v-validate="'required|max:165'"
                    v-model="editOnlineTcRequest.goodContact"
                    rows="4"
                    cols="50"
                  ></textarea>
                  <label
                    v-if="errors.first('academicyr.charAndConduct')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >General Conduct is required</label
                  >
                  <label v-else for="reasonid"
                    >General Conduct
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <textarea
                    class="form-control form-input"
                    v-validate="'required|max:165'"
                    name="reasoningRequest"
                    v-model="editOnlineTcRequest.reasonForLeaving"
                    rows="4"
                    cols="50"
                  ></textarea>
                  <label
                    v-if="errors.first('academicyr.reasoningRequest')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Reason for Leaving the School is required</label
                  >
                  <label v-else for="reasonid"
                    >Reason for Leaving the School
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!editOnlineTcRequest.detainedToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="passYear"
                    id="deptlistid"
                    @change="clearValues('promote')"
                    v-validate="'required'"
                    v-model="editOnlineTcRequest.promoteToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.passYear')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Passed and Promoted to class is required</label
                  >
                  <label v-else for="reasonid"
                    >Passed and Promoted to class
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!editOnlineTcRequest.detainedToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="nextAcademicYear"
                    id="deptlistid"
                    :disabled="!editOnlineTcRequest.promoteToClass"
                    v-validate="'required'"
                    v-model="editOnlineTcRequest.promoteToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>

                  <label
                    v-if="errors.first('academicyr.nextAcademicYear')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Pass Academic Year is required</label
                  >
                  <label v-else for="reasonid"
                    >Pass Academic Year <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!editOnlineTcRequest.promoteToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="AcademicYear"
                    id="deptlistid"
                    @change="clearValues('detained')"
                    v-model="editOnlineTcRequest.detainedToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label for="countrylistid">Detained in Class </label>
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!editOnlineTcRequest.promoteToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="detainedAcademicYear"
                    id="deptlistid"
                    v-validate="
                      editOnlineTcRequest.detainedToClass ? 'required' : false
                    "
                    :disabled="!editOnlineTcRequest.detainedToClass"
                    v-model="editOnlineTcRequest.detainedToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.detainedAcademicYear')"
                    class="vi-error"
                    style="top: -1em"
                    >Detained Academic year is required</label
                  >
                  <label for="countrylistid"
                    >Detained Academic year
                    <span
                      class="required"
                      v-if="editOnlineTcRequest.detainedToClass"
                      >*</span
                    ></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                  <textarea
                    name="comment"
                    v-model="editOnlineTcRequest.statusComments"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                  ></textarea>

                  <label for="tocmts"
                    >Comments <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
             <div
              class="form-row p-2"
              v-if="editOnlineTcRequest.certificateType != 'Transfer Certificate'"
            >
              <div class="form-group input-group col-md-6 mt-md-3" v-if="editOnlineTcRequest.certificateType == 'Conduct Certificate'">
                <span class="has-float-label">
                  <textarea
                    name="comment"
                    v-model="editOnlineTcRequest.previewConduct"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                  ></textarea>

                  <label for="tocmts"
                    >General Conduct <span class="required">*</span></label
                  >
                </span>
              </div>
                <div class="form-group input-group col-md-6 mt-md-3" v-else >
                <span class="has-float-label">
                  <textarea
                    name="comment"
                    v-model="editOnlineTcRequest.previewBonConduct"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                  ></textarea>

                  <label for="tocmts"
                    >Bonafide Details <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                  <textarea
                    name="comment"
                    v-model="editOnlineTcRequest.statusComments"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                  ></textarea>

                  <label for="tocmts"
                    >Comments <span class="required">*</span></label
                  >
                </span>
              </div>
              
            </div>
            <div class="form-row p-2" v-if="editOnlineTcRequest.document.path">
              <div class="form-group input-group col-md-4 mt-3">
                <label>
                  <span>Upload Document <span class="required">*</span></span>
                </label>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <button
                  class="btn btn-doc btn-sm"
                  style="background-color: darkseagreen"
                  @click.prevent="
                    preViewDocument(editOnlineTcRequest.document.path)
                  "
                  v-if="editOnlineTcRequest.document.path"
                >
                  View
                </button>
                <!-- <button class="btn btn-doc btn-sm" style="margin-left: 10px;background-color: indianred;"   @click.prevent="removeDocument(onlineTcRequest.document.path)" v-if="onlineTcRequest.document.path">
                              Delete
                            </button> -->
              </div>
            </div>
            <div
              class="pt-3 text-right dk_iconsml"
              style="border-top: 1px solid #e5e5e5"
            >
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                style="width: 19%;"
                @click.prevent="updateDetails"
              >
                  <span>Verify And Update</span>
              </button>

              <button
                @click.prevent="closeEditPopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
  </div>
  
    <b-modal id="generateSection" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeGenertePopUp">×</button>
      <div class="contact-form">
        <h2>Certificate Request</h2>
        <br />
        <div style="height: 350px; overflow-y: auto">
          <form
            data-vv-scope="academicyr"
            id="academicyr"
            accept-charset="utf-8"
          >
            <div class="form-row p-2">
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <v-select
                          placeholder="Search Student Name or Student Roll No"
                          class="form-control web-form-input"
                          label="label"
                          @search="getSearchStudentRollNoList"
                          :options="optionStudentList"
                          :reduce="label => label.value"
                          @input="selectedOption"
                         
                        >
                          <span style="display: none !important;" slot="no-options"></span>
                        </v-select>


                  <label for="tctypeid"
                    >Search Student / Roll No <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <select
                    :disabled="!selectedStudent"
                    class="form-control form-input"
                    id="tctypeid"
                    name="tctype"
                    @change="clearAllValues"
                    v-model="selectedCertificateType"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in certificateTypes"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="curnbranid">
                    Select Certificate Type
                    <span class="required">*</span></label
                  >
                </span>
              </div>
            </div>
            <div
              class="form-row p-2"
              v-if="
                selectedCertificateType &&
                selectedCertificateType == 'Transfer Certificate'
              "
            >
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    v-validate="'required'"
                    class="form-control form-input"
                    id="tctypeid"
                    name="tctype"
                    v-model="onlineTcRequest.type"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in typeList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.tctype')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Type is required</label
                  >

                  <label v-else for="tctypeid"
                    >Type <span class="required">*</span></label
                  >
                </span>
              </div>
           
              <div class="form-group input-group col-md-6 mt-2">
                <span class="has-float-label">
                  <select
                    
                    class="form-control form-input"
                    id="reasonid"
                    name="reason"
                    v-validate="'required'"
                    v-model="onlineTcRequest.reason"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in reasonList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.reason')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Reason for Transfer is required</label
                  >
                  <label v-else for="reasonid"
                    >Reason for Transfer <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                  <textarea
                    
                    v-validate="'required'"
                    name="comment"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                    v-model="onlineTcRequest.comments"
                  ></textarea>
                  <label
                    v-if="errors.first('academicyr.comment')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Comments is required</label
                  >
                  <label v-else for="tocmts"
                    >Comments <span class="required">*</span></label
                  >
                </span>
              </div>

              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    class="form-control form-input"
                    onkeydown="return false"
                    v-model="onlineTcRequest.lastAttend"
                    name="schoolLastDay"
                    v-validate="'required'"
                    id="addlid"
                    placeholder="Admission Deadline"
                  />
                  <label
                    v-if="errors.first('academicyr.schoolLastDay')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Last day at School is required</label
                  >
                  <label v-else for="reasonid"
                    >Last day at School <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    class="form-control form-input"
                    onkeydown="return false"
                    v-model="onlineTcRequest.dateOfLeaving"
                    name="dateOfLeaving"
                    v-validate="'required'"
                    id="addlid"
                    placeholder="Admission Deadline"
                  />

                  <label
                    v-if="errors.first('academicyr.dateOfLeaving')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Date of Leaving the School is required</label
                  >
                  <label v-else for="reasonid"
                    >Date of Leaving the School
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <textarea
                    name="charAndConduct"
                    class="form-control form-input"
                    v-validate="'required'"
                    v-model="onlineTcRequest.goodContact"
                    rows="4"
                    cols="50"
                  ></textarea>
                  <label
                    v-if="errors.first('academicyr.charAndConduct')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >General Conduct is required</label
                  >
                  <label v-else for="reasonid"
                    >General Conduct
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                <span class="has-float-label">
                  <textarea
                    class="form-control form-input"
                    v-validate="'required'"
                    name="reasoningRequest"
                    v-model="onlineTcRequest.reasonForLeaving"
                    rows="4"
                    cols="50"
                  ></textarea>
                  <label
                    v-if="errors.first('academicyr.reasoningRequest')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Reason for Leaving the School is required</label
                  >
                  <label v-else for="reasonid"
                    >Reason for Leaving the School
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!onlineTcRequest.detainedToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="passYear"
                    id="deptlistid"
                    @change="clearValues('promote')"
                    v-validate="'required'"
                    v-model="onlineTcRequest.promoteToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.passYear')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Passed and Promoted to class is required</label
                  >
                  <label v-else for="reasonid"
                    >Passed and Promoted to class
                    <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!onlineTcRequest.detainedToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="nextAcademicYear"
                    id="deptlistid"
                    :disabled="!onlineTcRequest.promoteToClass"
                    v-validate="'required'"
                    v-model="onlineTcRequest.promoteToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>

                  <label
                    v-if="errors.first('academicyr.nextAcademicYear')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >Pass Academic Year is required</label
                  >
                  <label v-else for="reasonid"
                    >Pass Academic Year <span class="required">*</span></label
                  >
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!onlineTcRequest.promoteToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="AcademicYear"
                    id="deptlistid"
                    @change="clearValues('detained')"
                    v-model="onlineTcRequest.detainedToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label for="countrylistid">Detained in Class </label>
                </span>
              </div>
              <div
                class="form-group input-group col-md-6 pr-md-3 mt-3"
                v-if="!onlineTcRequest.promoteToClass"
              >
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    name="detainedAcademicYear"
                    id="deptlistid"
                    v-validate="
                      onlineTcRequest.detainedToClass ? 'required' : false
                    "
                    :disabled="!onlineTcRequest.detainedToClass"
                    v-model="onlineTcRequest.detainedToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                  <label
                    v-if="errors.first('academicyr.detainedAcademicYear')"
                    class="vi-error"
                    style="top: -1em"
                    >Detained Academic year is required</label
                  >
                  <label for="countrylistid"
                    >Detained Academic year
                    <span
                      class="required"
                      v-if="onlineTcRequest.detainedToClass"
                      >*</span
                    ></label
                  >
                </span>
              </div>
            </div>
            <div
              class="form-row p-2"
              v-if="
                selectedCertificateType &&
                selectedCertificateType != 'Transfer Certificate'
              "
            >
              <div class="form-group input-group col-md-6 mt-md-3">
                <span class="has-float-label">
                  <textarea
                    v-validate="'required'"
                    name="previewConductDetails"
                    v-model="previewConduct"
                    class="form-control form-input"
                    style="min-height: 60px; resize: none"
                    id="tocmts"
                  ></textarea>

                   <label
                    v-if="errors.first('academicyr.previewConductDetails')"
                    class="vi-error"
                    for="pwdid"
                    style="top: -1em"
                    >General Conduct is required</label
                  >
                  <label v-else for="reasonid"
                    >General Conduct
                    <span class="required">*</span></label
                  >

                </span>
              </div>
            </div>

            <div
              v-if="selectedCertificateType"
              class="pt-3 text-center dk_iconsml"
              style="border-top: 1px solid #e5e5e5"
            >
              <button v-if="selectedCertificateType"
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="previewDetails(selectedStudent, selectedCertificateType)"
              >
                  <span>Preview</span>
              </button>

              <button v-if="selectedCertificateType"
                type="button"
                class="btn btnsml"
                id="svbtn"
                @click.prevent="generateandApprove"
              >
                <span>Generate</span>
              </button>

              <button
                @click.prevent="closeGenertePopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
             <div
              v-else
              class="pt-3 text-right dk_iconsml"
            >
             
              <button
                @click.prevent="closeGenertePopUp"
                type="button"
                class="btn btncl clsmdl"
                id="clbtn"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="actionPopup" no-close-on-backdrop no-close-on-esc>
      <!-- <div class="modal-content"> -->
        <button type="button" class="close"  @click.prevent="closeActionPopUp">×</button>
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>
          {{
            updateActionType == "Approved" ? "Approve" : "Reject"
          }}
          Confirmation
        </h2>
        <br />
        Are you sure you want to
        {{ updateActionType == "Approved" ? "Approve" : "Reject" }} this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button style="width:inherit"
            @click.prevent="actionConfirm"
            type="button"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button
            @click.prevent="closeActionPopUp"
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="generatePopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="generatePopupClose">×</button>
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Please select status</h2>
        <br />
        <div class="form-row">
          <div class="form-group input-group col-md-6 mt-3">
            <span class="has-float-label">
              <select
                class="form-control form-input"
                id="Reportingid"
                v-model="reportingTo"
                name="Reporting"
              >
                <option
                  v-for="(item, index) in adminRequestStatus"
                  :key="index"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>

              <label for="acyearid"
                >Status <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-6 mt-3">
            <span class="has-float-label">
              <textarea
                name="comment"
                class="form-control form-input"
                style="min-height: 60px; resize: none"
                id="tocmts"
                v-model="onlineTcRequest.statusComments"
              ></textarea>

              <label for="acyearid">Comments </label>
            </span>
          </div>
        </div>
        <div
          class="form-row"
          v-if="reportingTo == 'In-Person Collect' || reportingTo == 'Online'"
        >
          <div class="form-group input-group col-md-4 mt-3">
            <label>
              <span>Upload Document <span class="required">*</span></span>
            </label>
          </div>
          <div class="form-group input-group col-md-6 mt-3">
            <button
              class="btn btn-doc btn-sm"
              style="background-color: darkseagreen"
              @click.prevent="preViewDocument(onlineTcRequest.document.path)"
              v-if="onlineTcRequest.document.path"
            >
              View
            </button>
            <button
              class="btn btn-doc btn-sm"
              style="margin-left: 10px; background-color: indianred"
              @click.prevent="removeDocument(onlineTcRequest.document.path)"
              v-if="onlineTcRequest.document.path"
            >
              Delete
            </button>

            <input
              v-else
              type="file"
              ref="tcDocument"
              name="tcDocument"
              accept="application/pdf, image/jpg, image/png"
              @change="updateFile('tcDocument')"
            />
          </div>
        </div>
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            :disabled="!reportingTo"
            @click.prevent="confirmSubmit"
            type="button"
            class="btn btnsml"
            id="svbtn"
            style="width: 10%;"
          >
              <span>Save</span>
          </button>
          <button
            @click.prevent="generatePopupClose"
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="generateNeedMoreInfoShow" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="generateNeedMoreInfoPopupClose">×</button>
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Need more information</h2>
        <br />
        <div class="form-row">
          <div class="form-group input-group col-md-6 mt-3">
            <span class="has-float-label">
              <input
                disabled
                type="text"
                name="studentLastName"
                v-model="needMoreInfoRequest.status"
                class="form-control form-input"
                id="stdLastName"
                placeholder="Last Name"
              />

              <label for="acyearid"
                >Status <span class="required">*</span></label
              >
            </span>
          </div>
          <div class="form-group input-group col-md-12 mt-3">
            <span class="has-float-label">
              <textarea
                name="comment"
                class="form-control form-input"
                style="min-height: 60px; resize: none"
                id="tocmts"
                v-model="needMoreInfoRequest.comments"
              ></textarea>

              <label for="acyearid"
                >Comments <span class="required">*</span></label
              >
            </span>
          </div>
        </div>
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            :disabled="!needMoreInfoRequest.comments"
            @click.prevent="confirmSubmitForNeedMoreInformation"
            type="button"
            class="btn btnsml"
            style="width: 127px;"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button
            @click.prevent="generateNeedMoreInfoPopupClose"
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="printAction">
      <div class="row mt-3" id="sssdd">
        <div class="col-lg-12">
          <div class="widjet py-3 pl-3 pr-3">
            <div class="row">
              <div class="col-md-3 text-center">
                <img
                  width="200"
                  height="120"
                  v-if="schoolDetails.image && schoolDetails.image.path"
                  :src="schoolDetails.image.path"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
                <img
                  v-else
                  width="200"
                  height="120"
                  :src="schoolDetails.image"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />

                <!-- <img src="../../assets/img/velammalLogo.png"> -->
              </div>
              <div class="col-md-9 text-center">
                <h1>{{ schoolDetails.accountName }}</h1>
                <p>
                  {{ schoolDetails.address }}, {{ schoolDetails.street }},
                  {{ schoolDetails.district ? schoolDetails.district : "" }},
                  {{ schoolDetails.state ? schoolDetails.state : "" }},
                  {{ schoolDetails.pincode }}, {{ schoolDetails.country
                  }}<br /><b>Affiliate No. 223434534</b> <br /><b
                    v-if="schoolDetails.landlineNo"
                    >P : </b
                  >
                  {{
                    schoolDetails.landlineNo
                      ? "044 -" + " " + schoolDetails.landlineNo
                      : ""
                  }}
                  | <b>E :</b> <a
                    v-if="schoolDetails.email"
                    href="mailto:info@sairamgroup.in"
                    >{{ schoolDetails.email }}</a
                  >
                  | <b>W : </b
                  ><a
                    :href="schoolDetails.website"
                    target="_black"
                    v-if="schoolDetails.website"
                    >{{ schoolDetails.website }}</a
                  >
                </p>
              </div>
            </div>
            <div class="dispflex p-2 mt-2" style="background: #f7f7f7">
              <div style="width: 15%"><b>Date : {{ new Date() | dateFormat }} </b></div>
              <b>TRANSFER CERTIFICATE</b>
              <div style="width: 25%"><b>Application No: <span><input v-model="contactDetails.rollNoAllocated" name="deadLine" id="addlid" ></span></b></div>
            </div>
            <div class="py-3 onlinetc">
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem"><label>Name of Student:</label></div>
                <div class="fielditem">
                  <span
                    >{{
                      contactDetails.firstName + " " + contactDetails.lastName
                    }}
                  </span>
                </div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem"><label>Date of Birth:</label></div>
                <div class="fielditem">
                  <span>{{ contactDetails.dob | dateFormat }}</span>
                </div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem"><label>Nationality:</label></div>
                <div class="fielditem"><span>Indian</span></div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem">
                  <label>
                    {{
                      contactDetails.pointOfContact == "Father"
                        ? "Father"
                        : "Mother"
                    }}
                    Name:</label
                  >
                </div>
                <div class="fielditem">
                  <span>{{
                    contactDetails.parent
                      ? contactDetails.parent.firstName +
                        " " +
                        contactDetails.parent.lastName
                      : ""
                  }}</span>
                </div>
              </div>

              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem"><label>Gender:</label></div>
                <div class="fielditem">
                  <span>{{ contactDetails.gender }}</span>
                </div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem"><label>Last day at School:</label></div>
                <div class="fielditem">
                  <span>{{ tcRequest.lastAttend | dateFormat }}</span>
                </div>
                <!-- <div class="fielditem">
                  <input
                    type="date"
                    onkeydown="return false"
                    v-model="tcRequest.lastAttend"
                    disabled
                    name="deadssssLine"
                    id="addlid"
                    placeholder="Admission Deadline"
                  />
                </div> -->
              </div>
              <!-- <ol class="listnone">
                <li>
                  
                </li>
               
              </ol> -->
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem">
                  <label>Date of Admission into the School:</label>
                </div>
                <div class="fielditem" style="width: 70%;
    padding: 6px;
    white-space: normal;">
                <p> 
                  <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">{{ contactDetails.createdDate | dateFormat   }}</b> Academic Year:
                  <!-- <span -->
                    <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">
                      {{
                        contactDetails.academicYear
                          ? contactDetails.academicYear.from +
                            " " +
                            contactDetails.academicYear.to
                          : ""
                      }}
                    </b>  <br>The present class:
                 <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">{{
                      contactDetails.class ? contactDetails.class.className : ""
                    }}</b>
                  Academic Year:
                  <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">
                      {{
                        contactDetails.academicYear
                          ? contactDetails.academicYear.from +
                            " " +
                            contactDetails.academicYear.to
                          : ""
                      }}</b
                    
                  ></p>
                 
                </div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem">
                  <label>Date of Leaving the School:</label>
                </div>
                <div class="fielditem">
                  <span>{{ tcRequest.dateOfLeaving | dateFormat }}</span>
                </div>
                <!-- <div class="fielditem">
                  <input
                    disabled
                    type="date"
                    onkeydown="return false"
                    v-model="tcRequest.dateOfLeaving"
                    name="deadgfgfgfdLine"
                    id="addlid"
                    placeholder="Admission Deadline"
                  />
                </div> -->
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem">
                  <label>General Conduct:</label>
                </div>
                <div class="fielditem" style="width: 70%;
    padding: 6px;
    white-space: normal;">
                <p> {{  tcRequest.goodContact }} </p>
                  <!-- <span> -->
                   


                <!-- </span> -->
                  <!-- <textarea
                    disabled
                    v-model="tcRequest.goodContact"
                    rows="8"
                    cols="100"
                  ></textarea> -->
                </div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem"><label>Any Fee Dues:</label></div>
                <div class="fielditem"><span>Not Paid</span></div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem">
                  <label>Reason for Leaving the School:</label>
                </div>
                <div class="fielditem" style="width: 70%;
    padding: 6px;
    white-space: normal;">
                <p> {{  tcRequest.reasonForLeaving }}</p>
                  <!-- <textarea
                    disabled
                    v-model="tcRequest.reasonForLeaving"
                    rows="4"
                    cols="50"
                  ></textarea> -->
                </div>
              </div>
              <div class="dispflex" style="justify-content: flex-start">
                <div class="lblitem">
                  <label>Promotional Details:</label>
                </div>
                <div v-if="tcRequest.promoteToClass" class="fielditem" style="width: 70%;padding: 6px;white-space: normal;">
                  Passed and Promoted to class: <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">{{ getClassName(tcRequest.promoteToClass)   }}</b>
                  
                  <!-- <span>{{ getClassName(tcRequest.promoteToClass) }}</span> -->
                  <!-- <select
                    disabled
                    name="AcademicYear"
                    id="deptlistid"
                    v-model="tcRequest.promoteToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select> -->

                  for academic year: <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">{{ getAcademicName(tcRequest.promoteToYear)   }}</b>
                  <!-- <select
                    disabled
                    name="AcademicYear"
                    id="deptlistid"
                    v-model="tcRequest.promoteToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select> -->
                 
                </div>
                <div v-if="tcRequest.detainedToClass" class="fielditem" style="width: 70%;padding: 6px;white-space: normal;">
                <p> {{  tcRequest.reasonForLeaving }}</p>
                 
                </div>
              </div>
              <!-- <label><b>Promotional Details:</b></label>
              <ol>
                <li v-if="tcRequest.promoteToClass">
                  Passed and Promoted to class:
                  <select
                    disabled
                    name="AcademicYear"
                    id="deptlistid"
                    v-model="tcRequest.promoteToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>

                  for academic year:
                  <select
                    disabled
                    name="AcademicYear"
                    id="deptlistid"
                    v-model="tcRequest.promoteToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                </li>
                <li v-if="tcRequest.detainedToClass">
                  Detained in Class:
                  <select
                    disabled
                    name="AcademicYear"
                    id="deptlistid"
                    v-model="tcRequest.detainedToClass"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>

                  for academic year:
                  <select
                    disabled
                    name="AcademicYear"
                    id="deptlistid"
                    v-model="tcRequest.detainedToYear"
                  >
                    <option :value="null">--Select--</option>
                    <option
                      v-for="(item, index) in academicYearList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.from }} - {{ item.to }}
                    </option>
                  </select>
                </li>
              </ol> -->
              <p class="alert alert-info p-1">
                <b
                  >Certified that the above information / particulars is as per
                  the school admission records</b
                >
              </p>
              <div class="dispflex pt-4 pb-4">
                <div style="padding-top: 61px;">
                  <label>Date: </label><span>{{ new Date() | dateFormat }}</span
                  ><br /><br />
                  <label>Name: </label><span><input :disabled="!tcRequest._id" class="form-control form-input" name="deadLine" id="addlid" ></span>
                </div>
                <div style="padding-top: 36px;">
                  <br /><br />
                  <label style="text-align: center"
                    >Signature of <br />
                    Class Teacher
                  </label>
                </div>
                <div style="padding-top: 26px;">
                  <br /><br />
                  <label style="text-align: center"
                    >Checked by<br /><span style="margin-left: 20%;"><input :disabled="!tcRequest._id" class="form-control form-input" name="deadLine" id="addlid" placeholder="full Name and Designation"></span>
                  </label>
                </div>
               <div>
                  <img
                    v-if="
                      schoolSetting.signature && schoolSetting.signature.path
                    "
                    :src="schoolSetting.signature.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                  <br />
                  <label style="text-align: center"
                    >Signature of principal<br />with official seal
                  </label>
                  <br />
                  <img
                    v-if="
                      schoolSetting.schoolSeal && schoolSetting.schoolSeal.path
                    "
                    :src="schoolSetting.schoolSeal.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pt-3 text-right dk_iconsml"
        style="border-top: 1px solid #e5e5e5"
      >
        <button
          type="button"
          class="btn btnsml"
          id="no-print"
          v-if="tcRequest._id"
          @click.prevent="printPreview"
        >
            <span>Print</span>
        </button>
        <button
          @click.prevent="closePopUp"
          type="button"
          class="btn btncl clsmdl"
          id="no-print"
        >
             <span>Cancel</span>
        </button>
      </div>
    </b-modal>
    <div id="printCC">
    <b-modal id="previewCC">
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="widjet py-3 pl-3 pr-3">
            <div class="row">
              <div class="col-md-3 text-center">
                <img
                  width="200"
                  height="120"
                  v-if="schoolDetails.image && schoolDetails.image.path"
                  :src="schoolDetails.image.path"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
                <img
                  v-else
                  width="200"
                  height="120"
                  :src="schoolDetails.image"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />

                <!-- <img src="../../assets/img/velammalLogo.png"> -->
              </div>
              <div class="col-md-9 text-center">
                <h1>{{ schoolDetails.accountName }}</h1>
                <p>
                  {{ schoolDetails.address }}, {{ schoolDetails.street }},
                  {{ schoolDetails.district ? schoolDetails.district : "" }},
                  {{ schoolDetails.state ? schoolDetails.state : "" }},
                  {{ schoolDetails.pincode }}, {{ schoolDetails.country
                  }}<br /><b>Affiliate No. 223434534</b> <br /><b
                    v-if="schoolDetails.landlineNo"
                    >P : </b
                  >
                  {{
                    schoolDetails.landlineNo
                      ? "044 -" + " " + schoolDetails.landlineNo
                      : ""
                  }}
                  | <b>E :</b> <a
                    v-if="schoolDetails.email"
                    href="mailto:info@sairamgroup.in"
                    >{{ schoolDetails.email }}</a
                  >
                  | <b>W : </b
                  ><a
                    :href="schoolDetails.website"
                    target="_black"
                    v-if="schoolDetails.website"
                    >{{ schoolDetails.website }}</a
                  >
                </p>
              </div>
            </div>
            <div class="dispflex p-2 mt-2" style="background: #f7f7f7">
              <div style="width: 15%">
                <b>Date : {{ new Date() | dateFormat }} </b>
              </div>
              <b>CONDUCT CERTIFICATE</b>
              <div style="width: 25%">
                <b>Application No: <input v-model="contactDetails.rollNoAllocated" name="deadLine" id="addlid" ></b>
              </div>
            </div>
            <div class="py-3 onlinetc">
              <p>
                This is certifiy that 
                <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;"
                  >{{
                    contactDetails.firstName + " " + contactDetails.lastName
                  }}
                </b>
                {{ contactDetails.gender == "Male" ? "Son" : "Daughter" }} Of
                <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;">{{
                  contactDetails.parent
                    ? contactDetails.parent.firstName +
                      " " +
                      contactDetails.parent.lastName
                    : ""
                }}</b>

                is/was student of Class
                <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;"
                  >{{
                    contactDetails.class ? contactDetails.class.className : ""
                  }}</b
                >

                of this School during the Academic Year
                <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;"
                  >
                    {{
                      contactDetails.academicYear
                        ? contactDetails.academicYear.from +
                          " - " +
                          contactDetails.academicYear.to
                        : ""
                    }}</b
                >

                and that
                {{ contactDetails.gender == "Male" ? "her" : "his" }} conduct
                and character are / have been
                <b style="border-bottom: 1px solid #a5a5a5;margin-right: 7px;"
                  >{{ previewConduct }}</b
                >
              </p>

              <p class="alert alert-info p-1">
                <b
                  >Certified that the above information / particulars is as per
                  the school admission records</b
                >
              </p>
              <div class="dispflex pt-4 pb-4">
                <div>
                  <label>Date: </label><span>{{ new Date() | dateFormat }}</span
                  ><br /><br />
                  <label>Name: </label><span><input :disabled="!tcRequest._id" class="form-control form-input" name="deadLine" id="addlid" ></span>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Signature of <br />
                    Class Teacher
                  </label>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Checked by<br /><span style="margin-left: 20%;"><input :disabled="!tcRequest._id" class="form-control form-input" name="deadLine" id="addlid" placeholder="full Name and Designation"></span>
                  </label>
                </div>
                <div>
                  <br /><br />

                  <img
                    v-if="
                      schoolSetting.signature && schoolSetting.signature.path
                    "
                    :src="schoolSetting.signature.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                  <label style="text-align: center"
                    >Signature of principal<br />with official seal
                  </label>
                  <img
                    v-if="
                      schoolSetting.schoolSeal && schoolSetting.schoolSeal.path
                    "
                    :src="schoolSetting.schoolSeal.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pt-3 text-right dk_iconsml" 
        style="border-top: 1px solid #e5e5e5"
      >
       <button
         v-if="tcRequest._id"
          type="button"
          class="btn btncl clsmdl"
          id="no-print"
          @click="printPreviewCC"
        >
             <span>Print</span>
        </button>
        <button
          @click.prevent="closePreviewCCPopUp"
          type="button"
          class="btn btncl clsmdl"
          id="no-print"
        >
             <span>Cancel</span>
        </button>
      </div>
    </b-modal>
    </div>
    <div id="printBC">
    <b-modal id="previewBC">
      <div class="row mt-3">
        <div class="col-lg-12">
          <div class="widjet py-3 pl-3 pr-3">
            <div class="row">
              <div class="col-md-3 text-center">
                <img
                  width="200"
                  height="120"
                  v-if="schoolDetails.image && schoolDetails.image.path"
                  :src="schoolDetails.image.path"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
                <img
                  v-else
                  width="200"
                  height="120"
                  :src="schoolDetails.image"
                  :alt="schoolDetails.accountName"
                  :title="schoolDetails.accountName"
                />
              </div>
              <div class="col-md-9 text-center">
                <h1>{{ schoolDetails.accountName }}</h1>
                <p>
                  {{ schoolDetails.address }}, {{ schoolDetails.street }},
                  {{ schoolDetails.district ? schoolDetails.district : "" }},
                  {{ schoolDetails.state ? schoolDetails.state : "" }},
                  {{ schoolDetails.pincode }}, {{ schoolDetails.country
                  }}<br /><b>Affiliate No. 223434534</b> <br /><b
                    v-if="schoolDetails.landlineNo"
                    >P : </b
                  >
                  {{
                    schoolDetails.landlineNo
                      ? "044 -" + " " + schoolDetails.landlineNo
                      : ""
                  }}
                  | <b>E :</b> <a
                    v-if="schoolDetails.email"
                    href="mailto:info@sairamgroup.in"
                    >{{ schoolDetails.email }}</a
                  >
                  | <b>W : </b
                  ><a
                    :href="schoolDetails.website"
                    target="_black"
                    v-if="schoolDetails.website"
                    >{{ schoolDetails.website }}</a
                  >
                </p>
              </div>
            </div>
            <div class="dispflex p-2 mt-2" style="background: #f7f7f7">
              <div style="width: 15%">
                <b>Date : {{ new Date() | dateFormat }} </b>
              </div>
              <b>BONAFIDE CERTIFICATE</b>
              <div style="width: 25%">
                <b>Application No: <input v-model="contactDetails.rollNoAllocated" name="deadLine" id="addlid" ></b>
              </div>
            </div>
            <div class="py-3 onlinetc">
              <p>
                This is certifiy that
                <span
                  >{{
                    contactDetails.firstName + " " + contactDetails.lastName
                  }}
                </span>
                {{ contactDetails.gender == "Male" ? "Son" : "Daughter" }} Of
                <span>{{
                  contactDetails.parent
                    ? contactDetails.parent.firstName +
                      " " +
                      contactDetails.parent.lastName
                    : ""
                }}</span>

                is/was Bonafide student studying in class
                <span
                  ><b>{{
                    contactDetails.class ? contactDetails.class.className : ""
                  }}</b></span
                >

                of this School during the Academic Year
                <span
                  ><b>
                    {{
                      contactDetails.academicYear
                        ? contactDetails.academicYear.from +
                          " - " +
                          contactDetails.academicYear.to
                        : ""
                    }}</b
                  ></span
                >
                {{ contactDetails.gender == "Male" ? "Her" : "His" }} Date of
                Birth is
                <span
                  ><b> {{ contactDetails.dob | dateFormat }}</b></span
                >
                and Place of Birth is <span><b></b></span>
              </p>

              <p class="alert alert-info p-1">
                <b
                  >Certified that the above information / particulars is as per
                  the school admission records</b
                >
              </p>
              <div class="dispflex pt-4 pb-4">
                <div>
                  <label>Date: </label><span>{{ new Date() | dateFormat }}</span
                  ><br /><br />
                  <label>Name: </label><span><input :disabled="!tcRequest._id" class="form-control form-input" name="deadLine" id="addlid" ></span>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Signature of <br />
                    Class Teacher
                  </label>
                </div>
                <div>
                  <br /><br />
                  <label style="text-align: center"
                    >Checked by<br /><span style="margin-left: 20%;"><input :disabled="!tcRequest._id" class="form-control form-input" name="deadLine" id="addlid" placeholder="full Name and Designation"></span>
                  </label>
                </div>
                <div>
                  <img
                    v-if="
                      schoolSetting.signature && schoolSetting.signature.path
                    "
                    :src="schoolSetting.signature.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                  <br />
                  <label style="text-align: center"
                    >Signature of principal<br />with official seal
                  </label>
                  <br />
                  <img
                    v-if="
                      schoolSetting.schoolSeal && schoolSetting.schoolSeal.path
                    "
                    :src="schoolSetting.schoolSeal.path"
                    alt="Avatar"
                    width="80"
                    height="80"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="pt-3 text-right dk_iconsml"
        style="border-top: 1px solid #e5e5e5"
      >
     
      <button v-if="tcRequest._id"
          type="button"
          class="btn btncl clsmdl"
          id="no-print"
          @click="printPreviewBC"
        >
             <span>Print</span>
        </button>
        <button
          @click.prevent="closePreviewBCPopUp"
          type="button"
          class="btn btncl clsmdl"
         id="no-print"
        >
             <span>Cancel</span>
        </button>
      </div>
    </b-modal>
     </div>
    <b-modal id="deleteReuestPopUp" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button style="width: 127px;"
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
  </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    />
  </div>
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import Select from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "candidates",
  data() {
    return {
      deleteId: null,
      previewConduct: "",
      previewBonConduct: "",
      viLoader: false,
      selectedStudent: null,
      selectedCertificateType: null,
      allStudentList: [],
      certificateTypes: [],
       filterCertificateTypes: [
        "Transfer Certificate",
        "Conduct Certificate",
        "Bonafide Certificate",
      ],
      onlineTcRequest: {
        type: null,
        schoolName: "",
        address: "",
        reason: "",
        comments: "",
        status: "",
        statusComments: "",
        state: "",
        country: "",
        lastAttend: null,
        dateOfLeaving: null,
        goodContact: "",
        dueFees: "",
        document: {
          path: "",
          key: "",
        },
        reasonForLeaving: "",
        promoteToClass: null,
        promoteToYear: null,
        detainedToClass: null,
        detainedToYear: null,
      },
       editOnlineTcRequest: {
        type: null,
        schoolName: "",
        address: "",
        reason: "",
        certificateType: '',
        previewConduct: '',
        previewBonConduct: '',
        comments: "",
        status: "",
        studentName: '',
        statusComments: "",
        state: "",
        country: "",
        lastAttend: null,
        dateOfLeaving: null,
        goodContact: "",
        dueFees: "",
        document: {
          path: "",
          key: "",
        },
        reasonForLeaving: "",
        promoteToClass: null,
        promoteToYear: null,
        detainedToClass: null,
        detainedToYear: null,
      },
      typeList: ["Internal School Transfer", "Other School"],
      statusList: [
        "Draft",
        "Submitted",
        "Approved",
        "Rejected",
        "Generated",
        "Online",
        "Couriered",
        "In-Person Collect",
        "Need further Info",
        "Verified",
      ],

      reasonList: ["Location Change", "School Change"],
      adminRequestStatus: ["Online", "Couriered", "In-Person Collect"],
      searchWords: "",
      searchCertificateType: "",
      reportingTo: "",
      skip: 0, // set the skip info
      limit: 9, // set the limit info
      onlineTcList: [],
      updateActionId: null,
      minPlanDate: null,
      maxPlanDate: null,
      updateActionType: "",
      sectionList: [],
      studentObj: {},
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      academicYearList: [],
      classList: [],
      eligibilityCriteria: [],
      candidateEditDetails: {},
      editId: null,
      needMoreInfoRequest: {
        status: "Need further Info",
        comments: "",
      },
      schoolDetails: {},
      schoolSetting: {},
      contactDetails: {},
      tcRequest: {
        lastAttend: null,
        dateOfLeaving: null,
        goodContact: "",
        dueFees: "",
        reasonForLeaving: "",
        promoteToClass: null,
        promoteToYear: null,
        detainedToClass: null,
        detainedToYear: null,
        _id: null,
      },
    };
  },
  created() {
    this.getOnlineTcRequest(true);
    this.getAcademicYearList();
    this.getClassList();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    optionStudentList() {
        return this.allStudentList.map(x => {
          return {
            label: x.firstName + " " + x.lastName + ' / ' + x.rollNoAllocated,
            value: x._id
          };
        });
      },
  },
  watch: {
   
    searchWords: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.skip = 0;
        (this.currentPageNumber = 1),
          (this.lastPageNumber = 1),
          (this.selected = 1),
          (this.totalCount = 0),
          (this.countAll = true),
          this.getOnlineTcRequest(false);
      }
    },
    searchCertificateType: function (currentVal, oldVal) {
      let hasCurrentParam = currentVal || "";
      let hasOldParam = oldVal || "";

      if (hasCurrentParam != hasOldParam) {
        this.skip = 0;
        (this.currentPageNumber = 1),
          (this.lastPageNumber = 1),
          (this.selected = 1),
          (this.totalCount = 0),
          (this.countAll = true),
          this.getOnlineTcRequest(false);
      }
    }
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
     this.$router.push({ path: data });
    },
    async getSearchStudentRollNoList(search) {
      this.allStudentList = []
     
      if (search != "") {
       
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          `/contact/getSearchStudentAndRollNumberList?search=${search}`,
          userData.token
        );
        if (response.isSuccess) {
          this.allStudentList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    
      } else {
        this.allStudentList = [];
      }
    },
    getClassName(id){
      if(id){
        let data = this.classList.find( x => x._id == id)

        if(data){
          return data.className
        }else{
          return ''
        }
      }
    },
    getAcademicName(id){
      if(id){
        let data = this.academicYearList.find( x => x._id == id)

        if(data){
          return data.from + ' ' + data.to 
        }else{
          return ''
        }
      }
    },
    

    selectedOption(value) {
      this.selectedStudent = value

      if(value){
       
       this.getStudentRequestList()
      }else{
        this.clearGenerateValues()
      }
      

    },
    async getStudentRequestList() {
      this.certificateTypes = []

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(`/contact/getOnlineCertificateRequestDetails?studentId=${this.selectedStudent}`,userData.token);
     
	     if (response.isSuccess) {
        const details = secureUI.secureGet(response.data);
        const onlineTcDetails = details.requestList || []

          let typeList = [
            "Transfer Certificate",
            "Conduct Certificate",
            "Bonafide Certificate",
          ]

          if(onlineTcDetails.length > 0){
            const getandmapcertificateTypeList = onlineTcDetails.map( x => x.certificateType)

            const newTypeList = typeList.filter(val => !getandmapcertificateTypeList.includes(val));
            
            this.certificateTypes = newTypeList
           
          }else{
           this.certificateTypes = typeList
          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
  },
    clearGenerateValues(){

        this.previewConduct = "",
        this.previewBonConduct = "",
        this.selectedStudent = null,
        this.selectedCertificateType = null,
        this.onlineTcRequest.type = null,
        this.onlineTcRequest.schoolName =  "",
        this.onlineTcRequest.address =  "",
        this.onlineTcRequest.reason =  "",
        this.onlineTcRequest.comments =  "",
        this.onlineTcRequest.status =  "",
        this.onlineTcRequest.statusComments =  "",
        this.onlineTcRequest.state =  "",
        this.onlineTcRequest.country =  "",
        this.onlineTcRequest.lastAttend =  null,
        this.onlineTcRequest.dateOfLeaving =  null,
        this.onlineTcRequest.goodContact =  "",
        this.onlineTcRequest.dueFees =  "",
        this.onlineTcRequest.document = {
          path: "",
          key: "",
        },
        this.onlineTcRequest.reasonForLeaving =  "",
        this.onlineTcRequest.promoteToClass =  null,
        this.onlineTcRequest.promoteToYear =  null,
        this.onlineTcRequest.detainedToClass =  null,
        this.onlineTcRequest.detainedToYear =  null
    },
    clearAllValues(){
        this.previewConduct = "",
        this.previewBonConduct = "",
        this.onlineTcRequest.type = null,
        this.onlineTcRequest.schoolName =  "",
        this.onlineTcRequest.address =  "",
        this.onlineTcRequest.reason =  "",
        this.onlineTcRequest.comments =  "",
        this.onlineTcRequest.status =  "",
        this.onlineTcRequest.statusComments =  "",
        this.onlineTcRequest.state =  "",
        this.onlineTcRequest.country =  "",
        this.onlineTcRequest.lastAttend =  null,
        this.onlineTcRequest.dateOfLeaving =  null,
        this.onlineTcRequest.goodContact =  "",
        this.onlineTcRequest.dueFees =  "",
        this.onlineTcRequest.document = {
          path: "",
          key: "",
        },
        this.onlineTcRequest.reasonForLeaving =  "",
        this.onlineTcRequest.promoteToClass =  null,
        this.onlineTcRequest.promoteToYear =  null,
        this.onlineTcRequest.detainedToClass =  null,
        this.onlineTcRequest.detainedToYear =  null
    },
     showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deleteReuestPopUp");
    },
     closeDeletePopUp(){
      this.$bvModal.hide("deleteReuestPopUp");
      this.deleteId = null;
    },

    async getAllStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/contact/getStudentList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.allStudentList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    generateSectionOpen() {
      this.$bvModal.show("generateSection");
    },
    clearValues(type) {
      if (type == "promote") {
        (this.onlineTcRequest.promoteToYear = null),
          (this.onlineTcRequest.detainedToClass = null),
          (this.onlineTcRequest.detainedToYear = null);
      } else {
        (this.onlineTcRequest.promoteToClass = null),
          (this.onlineTcRequest.promoteToYear = null),
          (this.onlineTcRequest.detainedToYear = null);
      }
    },
    async navToSelectedPage(selectedPageNo) {
      this.skip = this.limit * (selectedPageNo - 1);

      this.countAll = false;

      this.getOnlineTcRequest(false);
    },

    async goToNextPage() {
      this.countAll = false;
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = this.limit * (this.currentPageNumber - 1);

      this.getOnlineTcRequest(false);
    },

    async goToPreviousPage() {
      this.countAll = false;
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = this.limit * (this.currentPageNumber - 1);

      this.getOnlineTcRequest(false);
    },

    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    preViewDocument(path) {
      window.open(path, "_blank");
    },
    updateAction(onlineAdmissionId, actionType, status) {
      if (actionType == "Approved" && status == "Submitted") {
        this.$toasted.error(
          "Please fill in the required information in Request..."
        );
      } else {
        (this.updateActionId = onlineAdmissionId),
          (this.updateActionType = actionType);
        this.$bvModal.show("actionPopup");
      }
    },
    openGeneratePopUp(onlineAdmissionId) {
      (this.updateActionId = onlineAdmissionId),
        this.$bvModal.show("generatePopup");
    },
    openGenerateNeedMoreInfoPopUp(onlineAdmissionId, comments) {
      (this.updateActionId = onlineAdmissionId),
        (this.needMoreInfoRequest.comments = comments);
      this.$bvModal.show("generateNeedMoreInfoShow");
    },
    generatePopupClose() {
      this.$bvModal.hide("generatePopup");
      this.reportingTo = "";
      (this.updateActionId = null), this.removeDocument();
    },
    generateNeedMoreInfoPopupClose() {
      this.$bvModal.hide("generateNeedMoreInfoShow");
      this.reportingTo = "";
      (this.updateActionId = null), (this.needMoreInfoRequest.comments = "");
    },
    async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.classList = [];
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    removeDocument() {
      this.onlineTcRequest.document.path = "";
      this.onlineTcRequest.document.key = "";
    },
    async updateFile(docType) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const docFileDetails = this.$refs[docType].files[0];
        const fd = new FormData();
        fd.append(
          "doc",
          docFileDetails,
          docFileDetails.name,
          docFileDetails.actType
        );
        const response = await ViService.viPutOnly(
          `/document/OnlineTc/Document/uploadFile`,
          fd,
          userData.token
        );
        
        if (response.isSuccess) {
          this.onlineTcRequest.document.path = response.data.Location;
          this.onlineTcRequest.document.key = response.data.Key;

          this.$toasted.success("Upload Successfully");
         
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
     async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.viLoader = true;
        const response = await ViService.viDelete(
          `/contact/deleteCertificateRequest/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.closeDeletePopUp();
          this.getOnlineTcRequest(false);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    async print(index) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const details = this.onlineTcList[index];
        if (details && details.student._id) {
          const response = await ViService.viXGet(
            `/contact/getStudentTcPrintDetails?studentId=${details.student._id}`,
            userData.token
          );
           
          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.schoolDetails = resultData.school || {},
            this.contactDetails = resultData.student || {}
            this.schoolSetting = resultData.schoolSetting || {}

            if(details.certificateType == 'Transfer Certificate'){
            this.tcRequest.lastAttend = details.lastAttend ? details.lastAttend.substring(0, 10): null;
            this.tcRequest.dateOfLeaving = details.dateOfLeaving ? details.dateOfLeaving.substring(0, 10) : null;
            this.tcRequest.goodContact = details.goodContact;
            this.tcRequest.dueFees = details.dueFees;
            this.tcRequest.reasonForLeaving = details.reasonForLeaving;
            this.tcRequest.promoteToClass = details.promoteToClass;
            this.tcRequest.promoteToYear = details.promoteToYear;
            this.tcRequest.detainedToClass = details.detainedToClass;
            this.tcRequest.detainedToYear = details.detainedToYear;
            

       
            this.$bvModal.show("printAction");
            }

            if(details.certificateType == 'Conduct Certificate'){
              this.previewConduct = details.previewConduct
              this.$bvModal.show("previewCC");


            }
            if(details.certificateType == 'Bonafide Certificate'){
              this.$bvModal.show("previewBC");
            }
            this.tcRequest._id = details._id;
           
          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      
    },
    async previewDetails(studentId, Type) {
    

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          `/contact/getStudentTcPrintDetails?studentId=${studentId}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.schoolDetails = resultData.school || {},
          this.contactDetails = resultData.student || {};
          this.schoolSetting = resultData.schoolSetting || {}
        }

       

        if (Type == "Conduct Certificate") {
          this.tcRequest._id = null
          this.$bvModal.show("previewCC");
        }
        if (Type == "Bonafide Certificate") {
          this.tcRequest._id = null
          this.$bvModal.show("previewBC");
        }
         if (Type == "Transfer Certificate") {
          
            this.tcRequest.lastAttend = this.onlineTcRequest.lastAttend ? this.onlineTcRequest.lastAttend.substring(0, 10): null;
            this.tcRequest.dateOfLeaving = this.onlineTcRequest.dateOfLeaving ? this.onlineTcRequest.dateOfLeaving.substring(0, 10) : null;
            this.tcRequest.goodContact = this.onlineTcRequest.goodContact;
            this.tcRequest.dueFees = this.onlineTcRequest.dueFees;
            this.tcRequest.reasonForLeaving = this.onlineTcRequest.reasonForLeaving;
            this.tcRequest.promoteToClass = this.onlineTcRequest.promoteToClass;
            this.tcRequest.promoteToYear = this.onlineTcRequest.promoteToYear;
            this.tcRequest.detainedToClass = this.onlineTcRequest.detainedToClass;
            this.tcRequest.detainedToYear = this.onlineTcRequest.detainedToYear;
            this.tcRequest._id = null
            this.$bvModal.show("printAction");
        }
      }
    },
    closePreviewBCPopUp() {
      this.$bvModal.hide("previewBC");
    },
    closePreviewCCPopUp() {
      this.$bvModal.hide("previewCC");
    },
    closePopUp() {
      this.$bvModal.hide("printAction");
    },
    closeEditPopUp() {
      this.$bvModal.hide("printSection");
    },

    openEditPopUp(index) {

      const studentDetails = this.onlineTcList[index];

      const cloneStudentDetails = { ...studentDetails };
       
      if(cloneStudentDetails.certificateType == 'Bonafide Certificate'){
        this.editOnlineTcRequest.previewBonConduct = cloneStudentDetails.previewBonConduct
        this.editOnlineTcRequest.certificateType = cloneStudentDetails.certificateType
        this.editOnlineTcRequest.studentName = cloneStudentDetails.student.firstName + ' ' + cloneStudentDetails.student.lastName
      }
      if(cloneStudentDetails.certificateType == 'Conduct Certificate'){
        this.editOnlineTcRequest.previewConduct = cloneStudentDetails.previewConduct
        this.editOnlineTcRequest.studentName = cloneStudentDetails.student.firstName + ' ' + cloneStudentDetails.student.lastName
        this.editOnlineTcRequest.certificateType = cloneStudentDetails.certificateType
      }
      if(cloneStudentDetails.certificateType == 'Transfer Certificate'){
        this.editOnlineTcRequest.certificateType = cloneStudentDetails.certificateType
        this.editOnlineTcRequest.studentName = cloneStudentDetails.student.firstName + ' ' + cloneStudentDetails.student.lastName
        
        this.editOnlineTcRequest.schoolName = cloneStudentDetails.schoolName
        this.editOnlineTcRequest.address = cloneStudentDetails.address
        this.editOnlineTcRequest.state = cloneStudentDetails.state
        this.editOnlineTcRequest.status = cloneStudentDetails.status
        this.editOnlineTcRequest.comments = cloneStudentDetails.comments
        this.editOnlineTcRequest.statusComments = cloneStudentDetails.statusComments
        this.editOnlineTcRequest.reason = cloneStudentDetails.reason
        this.editOnlineTcRequest.country = cloneStudentDetails.country
        this.editOnlineTcRequest.type = cloneStudentDetails.type
        this.editOnlineTcRequest.certificateType = cloneStudentDetails.certificateType

     
      this.editOnlineTcRequest.lastAttend = cloneStudentDetails.lastAttend ? cloneStudentDetails.lastAttend.substring(0, 10) : null;
      this.editOnlineTcRequest.dateOfLeaving = cloneStudentDetails.dateOfLeaving ? cloneStudentDetails.dateOfLeaving.substring(0, 10) : null;

      this.editOnlineTcRequest.goodContact = cloneStudentDetails.goodContact;
      this.editOnlineTcRequest.dueFees = cloneStudentDetails.dueFees;
      this.editOnlineTcRequest.reasonForLeaving = cloneStudentDetails.reasonForLeaving;
      this.editOnlineTcRequest.promoteToClass = cloneStudentDetails.promoteToClass;
      this.editOnlineTcRequest.promoteToYear = cloneStudentDetails.promoteToYear;
      this.editOnlineTcRequest.detainedToClass = cloneStudentDetails.detainedToClass;
      this.editOnlineTcRequest.detainedToYear = cloneStudentDetails.detainedToYear;
      

      this.editOnlineTcRequest.document.path = cloneStudentDetails.document
        ? cloneStudentDetails.document.path
        : "";
      this.editOnlineTcRequest.document.key = cloneStudentDetails.document
        ? cloneStudentDetails.document.key
        : "";

      }

      this.editId = cloneStudentDetails._id;

      this.$bvModal.show("printSection");
    },
    async printPreview() {

      const modal = document.getElementById("printAction");

      const cloned = modal.cloneNode(true);

      let section = document.getElementById("printSection");

      if (!section) {
        section = document.createElement("div");
        section.id = "printSection";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
     
    },
    async printPreviewCC() {

      const modal = document.getElementById("previewCC");

      const cloned = modal.cloneNode(true);

      let section = document.getElementById("printSection");

      if (!section) {
        section = document.createElement("div");
        section.id = "printSection";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
     
    },
     async printPreviewBC() {

      const modal = document.getElementById("previewBC");

      const cloned = modal.cloneNode(true);

      let section = document.getElementById("printSection");

      if (!section) {
        section = document.createElement("div");
        section.id = "printSection";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      window.print();
     
    },
    
    closeUpdatePoup() {
      this.$bvModal.hide("printSection");
    },
    closeActionPopUp() {
      this.$bvModal.hide("actionPopup");
      (this.updateActionId = null), (this.updateActionType = null);
    },

    async actionConfirm() {
      let userData = secureUI.sessionGet("user");

      const userName = userData.userDetails.firstName + " " + userData.userDetails.lastName;

      let obj = {
        status: this.updateActionType,
        actionBy: userName,
      };

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/contact/updateOnlineTcRequestAction/${this.updateActionId}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(this.updateActionType + " " + "Successfully");
          this.closeActionPopUp();
          this.getOnlineTcRequest(false);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async generateandApprove(){
       let isFormValid = false;

      await this.$validator.validateAll("academicyr").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {

      let obj = {
         studentId: this.selectedStudent,
         certificateType: this.selectedCertificateType,
         requestCreatedBy: 'School Admin'
      }

      if(this.selectedCertificateType == 'Transfer Certificate'){
          obj['address'] = this.onlineTcRequest.address
          obj['comments'] = this.onlineTcRequest.comments
          obj['country'] = this.onlineTcRequest.country
          obj['dateOfLeaving'] = this.onlineTcRequest.dateOfLeaving
          obj['detainedToClass'] = this.onlineTcRequest.detainedToClass
          obj['detainedToYear'] = this.onlineTcRequest.detainedToYear
          obj['document'] = this.onlineTcRequest.document
          obj['dueFees'] = this.onlineTcRequest.dueFees
          obj['goodContact'] = this.onlineTcRequest.goodContact
          obj['lastAttend'] = this.onlineTcRequest.lastAttend
          obj['promoteToClass'] = this.onlineTcRequest.promoteToClass
          obj['promoteToYear'] = this.onlineTcRequest.promoteToYear
          obj['reason'] = this.onlineTcRequest.reason
          obj['reasonForLeaving'] = this.onlineTcRequest.reasonForLeaving
          obj['schoolName'] = this.onlineTcRequest.schoolName
          obj['status'] = this.onlineTcRequest.status
          obj['type'] = this.onlineTcRequest.type
          obj['statusComments'] = this.onlineTcRequest.statusComments

      }
      if(this.selectedCertificateType == 'Conduct Certificate'){
           obj['previewConduct'] = this.previewConduct
      }
      if(this.selectedCertificateType == 'Bonafide Certificate'){
          obj['previewBonConduct'] = this.previewBonConduct
      }

      let userData = secureUI.sessionGet("user");

			if (!userData) {
				this.$toasted.error("Please login and do the action");
			} else {

      obj.status = 'Approved'
      obj.actionBy = userData.userDetails.firstName + " " + userData.userDetails.lastName
   
      const response = await ViService.viXPost("/contact/createCertificateRequest/admin",obj,userData.token);

      if (response.isSuccess) {
        this.$bvModal.hide("generateSection");
        this.clearGenerateValues()
        this.getOnlineTcRequest(false)
        this.$toasted.success(response.message);
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
    }
      }else{
        this.$toasted.error("Please fill required fields..");
      }

    },
    closeGenertePopUp(){
        this.$bvModal.hide("generateSection");
        this.clearGenerateValues()
    },
    async confirmSubmit() {
      let allowSave = true

      let obj = {
        status: this.reportingTo,
        statusComments: this.onlineTcRequest.statusComments,
        document: {
          path: "",
          Key: "",
        },
      };

      if (
        this.reportingTo == "Online" ||
        this.reportingTo == "In-Person Collect"
      ) {
        if(this.onlineTcRequest.document && this.onlineTcRequest.document.path){
        obj["document"]["path"] = this.onlineTcRequest.document.path;
        obj["document"]["key"] = this.onlineTcRequest.document.Key;
        }else{
          allowSave = false
          this.$toasted.error("Please Upload Attach Document");
        }
      }
      if(allowSave){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      
      } else {
        const response = await ViService.viXPut(
          `/contact/updateOnlineTcRequestStatus/${this.updateActionId}`,
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success("Updated Successfully");
          this.generatePopupClose();
          this.getOnlineTcRequest(false);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async confirmSubmitForNeedMoreInformation() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXPut(
          `/contact/updateOnlineTcRequestStatusAndNeedMoreInfo/${this.updateActionId}`,
          this.needMoreInfoRequest,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success("Updated Successfully");
          this.generateNeedMoreInfoPopupClose();
          this.getOnlineTcRequest(false);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

    async updateDetails() {
      let isFormValid = false;

      await this.$validator.validateAll("academicyr").then((result) => {
        isFormValid = result;
      });

      if (isFormValid) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          this.editOnlineTcRequest.status = "Verified";
          
          const response = await ViService.viXPut(
            `/contact/updateOnlineTcRequest/${this.editId}`,
            this.editOnlineTcRequest,
            userData.token
          );

          if (response.isSuccess) {
            this.$toasted.success(response.message);
            this.closeEditPopUp();
            this.getOnlineTcRequest(false);
          } else {
            this.onlineTcRequest.status = "Approved";
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }else{
        this.$toasted.error("Please fill required fields..");
      }
    },

    async getOnlineTcRequest(value) {
      
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if(value){
          this.viLoader = true;
        }
       

        const response = await ViService.viXGet(
          `/contact/getOnlineTcListView?skip=${this.skip}&limit=${this.limit}&certificateType=${this.searchCertificateType}&search=${this.searchWords}&isCount=${this.countAll}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.onlineTcList = resultData.studentOnlineTcList;
          if (this.countAll) {
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount / this.limit);
          }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    },
  },
  components: {
    "v-select": Select,
    ViSpinner,
  },
};
</script>

<style>
@media screen {
  #printSection {
    
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSection, #printSection * {
    visibility:visible;
  }
  #printSection {
    position:absolute;
    left:0;
    top:0;
  }
  #no-print,
  #no-print * {
    display: none !important;
  }
}
</style>